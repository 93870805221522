.character-sheet {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
  }
  
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .card {
    position: absolute;
    width: 60%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: scale(0.9);
    opacity: 0.5;
    transition: all 0.5s ease-in-out;
  }
  
  .card.active {
    transform: scale(1);
    opacity: 1;
    z-index: 10;
  }
  
  .card.inactive {
    transform: translateY(50px);
    opacity: 0.3;
    z-index: 1;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  button {
    padding: 10px 15px;
    background-color: #4caf50;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #388e3c;
  }
  