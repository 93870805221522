body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

.app-container2 {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.grid-container2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.card2 {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.card2 label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
    font-size: 14px;
    color:#333;
    
}

.card2 select,
.card2 textarea {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.card3 {
    background-color: #fff;
}
.card3 textarea {
    resize: vertical;
    height: 350px;
    width: 100%;
}

textarea {
    resize: vertical;
    height: 60px;
    width: 100%;
    
}

button {
    display: block;
    width: 100%;
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.generated-output2 {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-wrap; /* Ensures text wraps and line breaks are preserved */
    word-break: break-word; /* Forces long words to wrap to the next line */
    width: 100%; /* Ensures the container spans the available space */
    box-sizing: border-box; /* Includes padding and border in the element's total width */
  }

  .generated-output2 h3, .generated-output2 pre{
    color:#333
  }
  
  pre {
    white-space: pre-wrap; /* Wrap text inside <pre> tags */
    word-break: break-word; /* Break long words inside <pre> tags */
    margin: 0; /* Removes extra spacing */
  }
  
  
  
.copy-button {
    display: inline-block;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
}

.copy-button:hover {
    background-color: #0056b3;
}
.enhance-button {
    display: inline-block;
    margin-top: 10px;
    background-color: #ffa500;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
}

.enhance-button:hover {
    background-color: #e59400;
}
.api-key-section {
    margin-bottom: 20px;
  }
  
  .api-key-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .card2 button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.card2 button:hover {
    background-color: #0056b3;
}
.editable-prompt {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical; /* Allow vertical resizing only */
    margin-bottom: 10px;
  }
  .preview-image {
    width: 250px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px;
    margin-top: 10px;
    display: block;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}