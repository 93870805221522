/* Main page container */
.test-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Occupies full viewport height */
    width: 100%;
    font-family: Arial, sans-serif;
    color: #f0f0f0;
    background-color: #1f1f1f;
    overflow-y: scroll; /* Ensures scrolling */
    box-sizing: border-box;
}



.tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #2c2c2c;
    padding: 10px;
    border-bottom: 2px solid #444;
    width: 100%;
    max-width: 800px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.tabs button {
    background: none;
    color: #aaa;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
}

.tabs button:hover,
.tabs button.active {
    color: #fff;
    border-bottom: 2px solid #4CAF50;
}
  
/* Main content layout with side-by-side panels */
.main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    max-width: 820px;
    padding-top: 5px;
    box-sizing: border-box;
}

/* Left and right panels styling */
.left-panel, .right-panel {
    width: 49%;
    padding: 10px;
    background-color: #2c2c2c;
    border-radius: 8px;
    box-sizing: border-box;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .left-panel, .right-panel {
        width: 100%;
        padding: 10px;
    }
}

/* Hide scrollbar on desktop */
@media (min-width: 769px) {
    .test-page {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
    }

    .test-page::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
    }
}
  

  
  .left-panel label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .left-panel input, .left-panel select, .left-panel textarea {
    width: 100%;
    
    margin-top: 5px;
    background-color: #333;
    border: 1px solid #555;
    color: white;
    border-radius: 4px;
  }
  
  .attributes, .skills, .background {
    margin-top: 20px;
  }
  
  .attributes p, .skills p, .background p {
    margin: 5px 0;
  }
  .skills-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    column-gap: 10px;
  }
  
  .skills-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .skills-container li {
    margin-bottom: 5px;
  }
  
  .stat-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
}
.modal-content img {
    max-width: 100%;
    border-radius: 8px;
    margin: 20px 0;
  }

.stat-option {
    padding: 10px;
    margin: 10px 0;
    align-items: center;
    background-color: #444;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    transition: background-color 0.3s;
}

.stat-option p{
  text-align: center;
}
.stat-option button{
  background: #444;
  color: #fff;
  padding: 5px 10px;
  border: 1px solid #4CAF50;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-top: 5px;
}

.stat-option:hover {
    background-color: #555;
}
.character-portrait {
    margin-top: 20px;
    text-align: center;
  }
  
  .character-portrait .portrait {
    width: 100%;
    max-width: 150px; /* Set an appropriate size */
    border-radius: 8px;
    border: 2px solid #555;
  }
  .portrait-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-actions button:hover {
    background-color: #388e3c;
  }
  .skill-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.skill-list {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 10px;
}

.skill-list button {
    margin: 5px;
    padding: 10px;
    border: 1px solid #444;
    background-color: #333; /* Default background for unselected */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
}

.skill-list button.selected {
    background-color: #4caf50; /* Green background for selected */
    color: white;
}


.bottom-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}
/* Styles for the action buttons above tabs */
.action-buttons2 {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    background-color: #333;
    border-bottom: 2px solid #444;
}


.click-button {
  background: #444;
  color: #fff;
  padding: 5px 10px;
  border: 1px solid #4CAF50;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-top: 5px;
}

.click-buttons button:hover {
  background-color: #555;
}

.action-buttons button {
    background: #444;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
}

.action-buttons button:hover {
    background-color: #555;
}

.charSheet-buttons {
    background: #e6cf03;
    color: #000000;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
}

.portrait-modal .portrait-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four columns */
  gap: 10px;
}
  
  .portrait-option {
    width: 100px; /* Ensure a square shape */
    height: 100px; /* Ensure a square shape */
    object-fit: cover; /* Maintain aspect ratio and fill the area */
    border-radius: 8px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  
  .portrait-option:hover {
    border-color: #4caf50; /* Highlight on hover */
  }
  .dungeon-master-portrait {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    
  }
  .character-stats {
    
    padding: 10px;
    background-color: #2c2c2c;
    border-radius: 8px;
  }
  
  .character-stats h3 {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .stats-grid, .inventory-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 5px; /* Smaller gap between items */
  }
  
  .stats-grid p, .inventory-grid p {
    background-color: #444;
    color: white;
    padding: 5px;
    border-radius: 4px;
    text-align: center;
    margin: 0; /* Remove margins */
  }
  
  
  .stats-grid p:hover, .inventory-grid p:hover {
    background-color: #666;
  }
  .error-text {
    color: #ff4c4c; /* Red for error messages */
    font-size: 0.9rem;
    margin-top: 5px;
  }
  