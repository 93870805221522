.play-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #1f1f1f;
  color: #f0f0f0;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #4caf50;
}

.chat-box {
  width: 80%;
  max-width: 800px;
  max-height: 400px;
  overflow-y: auto; /* Enable scrolling */
  border: 1px solid #444;
  padding: 15px;
  background-color: #2c2c2c;
  border-radius: 8px;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.user-message,
.dm-message {
  margin: 5px 0;
}

.user-message {
  text-align: right;
  color: #4caf50;
}

.dm-message {
  text-align: left;
  color: #bbb;
}
.chat-input {
  flex: 1; /* Take up available space */
  padding: 10px;
  background-color: #333;
  border: 1px solid #555;
  color: #f0f0f0;
  border-radius: 4px 0 0 4px; /* Round only the left corners */
  font-size: 16px;
  margin-bottom: 10px;
}

.chat-input-container {
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 800px;
  margin-top: 10px;
}

.send-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 0 4px 4px 0; /* Round only the right corners */
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}
.send-button:hover {
  background-color: #388e3c;
}
.portraits-and-actions {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.portrait-wrapper {
  text-align: center;
  width: 120px;
}

.dungeon-master-portrait-pg,
.player-character-portrait-pg {
  width: 100px;
  height: auto;
  border-radius: 8px;
}

.pg-buttons {
  display: flex;
  flex-direction: column;
}

.pg-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pg-button:hover {
  background-color: #388e3c;
}

.pg-button img.icon {
  width: 32px;
  height: 32px;
}


@media (max-width: 768px) {
  .play-game-container {
    padding: 10px;
  }

  .chat-box {
    width: 90%;
    max-height: 300px;
  }

  .chat-input {
    width: 100%;
  }

  .portraits-and-actions {
    flex-direction: row; /* Stack portraits and buttons vertically */
    /* Add spacing between elements */
  }

  .portrait-wrapper {
    width: auto; /* Allow the portrait wrapper to scale naturally */
    text-align: center;
  }

  .dungeon-master-portrait-pg,
  .player-character-portrait-pg {
    width: 135px; /* Scale down portraits for mobile */
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px; /* Add spacing below portraits */
  }

  .pg-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    /* Adjust spacing between buttons */
  }

  .pg-button {
    width: 90%; /* Make buttons take up most of the screen width */
    padding: 8px; /* Adjust padding for mobile */
    font-size: 14px; /* Adjust font size for readability */
  }
}

.storyspeak-button {
  margin: 10px;
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.storyspeak-button:hover {
  background-color: #388e3c;
}

.actionspeak-button {
  margin: 10px;
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.actionspeak-button:hover {
  background-color: #388e3c;
}
.music-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.music-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 20px 0;
}

.music-controls select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #2c2c2c;
  color: #f0f0f0;
  font-size: 14px;
}

.music-controls .pg-button img {
  width: 24px;
  height: 24px;
}

#volume-slider {
  width: 150px;
}


.music-controls .pg-button:hover {
  background-color: #388e3c;
}
.settings-button img,
.listen-button img,
.dice-button img {
  width: 32px;
  height: 32px;
}



.back-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}
.message-divider {
  border: 0;
  height: 1px;
  background: #4caf50;
  margin: 10px 0;
  opacity: 0.6;
}
