/* General styling */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(120deg, #1f1f1f, #282828, #1f1f1f);
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
  color: #fff;
  text-align: center;
}

/* Gradient animation */
@keyframes gradient-animation {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.dice-roller {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.typing-text {
  font-size: 2rem;
  font-weight: bold;
  animation: typing 3s steps(20, end), blink 0.5s step-end infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid #4caf50;
}

.intro-text {
  font-size: 1.2rem;
  margin: 15px 0 30px;
  color: #ccc;
}

.controls {
  margin: 20px;
}

.dice-row {
  display: flex;
  align-items: center;
  justify-content: center; /* Centering content for desktop */
  margin-bottom: 10px;
}

.dice-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.dice-row label {
  margin: 0 10px;
}

.remove-button {
  background: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.remove-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 8px rgba(255, 77, 77, 0.5);
}

.add-button {
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.add-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 5px 15px rgba(76, 175, 80, 0.5);
}

.roll-button2 {
  background: linear-gradient(to right, #4caf50, #66bb6a);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  width: fit-content; /* Automatically adjusts to content width */
  max-width: 100%; /* Prevents overflow */
  transition: transform 0.3s, box-shadow 0.3s;
  justify-content: center;
  align-content: center;
}

.roll-button2:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 15px rgba(76, 175, 80, 0.5);
}

.clear-button {
  background: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  width: fit-content; /* Automatically adjusts to content width */
  max-width: 100%; /* Prevents overflow */
  transition: transform 0.3s, box-shadow 0.3s;
  justify-content: center;
  align-content: center;
}

.clear-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 15px rgba(255, 77, 77, 0.5);
}

.results-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.result-item {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
}

.grand-total {
  margin-top: 20px;
  text-align: center;
  font-size: 1.5rem;
  color: #4caf50;
}

.dice-animation {
  font-size: 48px;
  animation: roll 1.5s infinite;
}




@keyframes roll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.roll-button2, .clear-button {
  width: 80%; /* Matches the width of the character list or chat box */
  max-width: 400px;
}

@media (max-width: 768px) {
  .buttons-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .roll-button2, .clear-button {
    width: 100%; /* Adjust to full width on mobile */
  }
}