/* General styling */
body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    background: linear-gradient(120deg, #1f1f1f, #282828, #1f1f1f);
    background-size: 200% 200%;
    animation: gradient-animation 10s ease infinite;
    color: #fff;
    text-align: center;
  }
  
  /* Gradient animation */
  @keyframes gradient-animation {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  
  /* Options container */
  .options-container {
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
  }
  
  /* Typing text for heading */
  .typing-text {
    font-size: 2rem;
    font-weight: bold;
    animation: typing 3s steps(20, end), blink 0.5s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid #4caf50;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  
  /* Introductory text */
  .intro-text {
    font-size: 1.2rem;
    color: #ccc;
    margin: 15px 0 30px;
  }
  
  /* Options grid */
  .options-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 90%;
    max-width: 800px;
  }
  
  /* Option cards */
  .option-card {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s, background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .option-card:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  }
  
  .option-card h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #4caf50;
  }
  
  .option-card p {
    font-size: 1rem;
    color: #ddd;
  }
  
  /* Footer */
  .footer-text {
    margin-top: 30px;
    font-size: 0.9rem;
    color: #888;
  }
  