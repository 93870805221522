body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

.app-container {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section {
    margin-bottom: 30px;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}

h2 {
    color: #333;
}

.input-textarea,
.input-field,
.input-select {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.action-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.action-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.story-output {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color:#333;
    text-align: left;
}

.story-paragraph {
    margin-bottom: 10px;
    line-height: 1.5;
    color:#333;
}

.audio-output {
    margin-top: 20px;
}

.download-link {
    display: inline-block;
    margin-top: 10px;
    color: #4caf50;
    text-decoration: none;
    font-weight: bold;
}

.download-link:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    font-weight: bold;
}
