body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(120deg, #1f1f1f, #282828, #1f1f1f);
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
  color: #fff;
  text-align: center;
}

@keyframes gradient-animation {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.dashboard-container {
  padding: 20px;
  text-align: center;
}

.typing-text {
  font-size: 2rem;
  font-weight: bold;
  animation: typing 3s steps(20, end), blink 0.5s step-end infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid #4caf50;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.intro-text {
  font-size: 1.2rem;
  margin: 15px 0 30px;
  color: #ccc;
}

.character-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.character-list {
  display: grid;
 
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px; /* Spacing between cards */
  list-style: none; /* Remove list dots */
  padding: 0; /* Remove default padding */
}

.character-card {
  background-color: #2c2c2c;
  border: 1px solid #555;
  border-radius: 8px;
  padding: 15px;
  color: #f0f0f0;
  text-align: center;
  transition: transform 0.2s, background-color 0.2s;
  cursor: pointer;
}

.character-card:hover {
  transform: scale(1.05);
  background-color: #333;
}


.button-row {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.icon-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.2s;
}

.icon-button:hover {
  transform: scale(1.1);
}

.placeholder-card {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-weight: bold;
  background-color: #444;
}

.portrait-thumbnail {
  width: 100%;
  max-width: 80px;
  border-radius: 8px;
  margin: 10px auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-thumbnail {
  width: 100%;
  max-width: 140px;
  border-radius: 2px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.modal-group {
  display: flex;
  justify-content: space-between; /* Ensures equal spacing between columns */
  align-items: center; /* Centers content vertically within the group */
  gap: 3px; /* Adds consistent spacing between columns */
}

.modal-centergroup {
  display: flex;
  flex-direction: column; /* Ensures vertical alignment for grouped content */
  align-items: center; /* Centers content horizontally */
  gap: 3px; /* Adds spacing between elements */
}

.modal-rightpanel, .modal-leftpanel {
  flex: 1; 
  padding: 5px; /* Adds consistent padding */
  text-align: left; /* Aligns text to the left for readability */
}

.modal-content-db {
  background-color: #2c2c2c;
  border-radius: 8px;
  color: #f0f0f0;
  width: 90%;
  max-width: 500px;
  max-height: 90%; /* Limits modal height */
  overflow-y: auto; /* Enables vertical scrolling within the modal */
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .modal-content {
    max-width: 95%; /* Makes modal narrower for smaller screens */
    max-height: 85%; /* Limits modal height further on mobile */
    padding: 15px;
  }

  .modal-columns {
    flex-direction: column; /* Stacks columns vertically on mobile */
    align-items: center; /* Centers content on mobile */
  }

  .column {
    width: 100%; /* Each column takes full width on mobile */
  }
}





.modal-content h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-content button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: #388e3c;
}
.modal-section {
  width: 100%;
}

.modal-columns {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.column p {
  background: #444;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.column p:hover {
  background: #666;
}

.modal-thumbnail2 {
  display: block;
  margin: 0 auto; /* Centers the image horizontally */
  max-width: 100px; /* Limits size for desktop */
  width: 35%; /* Adjusts size proportionally */
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .modal-thumbnail2 {
    max-width: 70px; /* Smaller size for mobile */
    width: 60%;
  }

  .modal-content {
    width: 95%;
    max-width: 400px; /* Makes modal narrower for mobile */
    padding: 15px;
  }

  .modal-columns {
    flex-direction: column; /* Stacks columns vertically on mobile */
    align-items: center; /* Centers content on mobile */
  }

  .column {
    width: 100%; /* Ensures each column takes full width on mobile */
  }
}
.roll-button {
  background: linear-gradient(to right, #4caf50, #66bb6a);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  width: fit-content; /* Automatically adjusts to content width */
  max-width: 100%; /* Prevents overflow */
  transition: transform 0.3s, box-shadow 0.3s;
}

.roll-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 15px rgba(76, 175, 80, 0.5);
}

.go-back-button {
  margin: 20px auto 0; /* Centers the button below the list */
}