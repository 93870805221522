/* General styling */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(120deg, #1f1f1f, #282828, #1f1f1f);
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
  color: #fff;
  text-align: center;
}

/* Gradient animation */
@keyframes gradient-animation {
  0%, 100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

/* Login container */
.login-container {
  max-width: 500px;
  margin: 100px auto;
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1.5s ease-in-out;
}

/* Typing effect for header */
.typing-text {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  animation: typing 3s steps(20, end), blink 0.5s step-end infinite;
  overflow: hidden;
  white-space: nowrap;
  border-right: 4px solid #4caf50;
  display: inline-block;
  width: 100%;
}

@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/* Google button */
.google-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #4caf50, #66bb6a);
  color: white;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.google-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 15px rgba(76, 175, 80, 0.5);
  background: linear-gradient(to right, #66bb6a, #4caf50);
}

/* Google icon in button */
.google-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
}

/* Footer text */
.footer-text {
  margin-top: 20px;
  font-size: 14px;
  color: #888;
  text-align: center;
}

.footer-text p {
  margin: 0;
}

/* Subtle fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.highlight-green{
  color:#4caf50
}