body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
  
 
  
  .user-message {
    text-align: right;
    color: blue;
  }
  
  .dm-message {
    text-align: left;
    color: green;
  }
  
  input {
    width: calc(100% - 80px);
    
  }
  
  button {
    padding: 10px 20px;
  }
  